.page-title {
    margin-top: 20px;
}
.service_box_container {
    margin: 10px ;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    /* justify-content: space-around; */

}

.list-box {
    margin-left: 50px;
}

.lists {
    margin-left: 50px;
}