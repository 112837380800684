@import "~react-image-gallery/styles/css/image-gallery.css";

.photo-about {

    background-image:url('../images/Glenan-Living-Best.jpg');
    

}

.top-intro-pg {
    margin-top: 15px;
}
.image-gallery-wrapper {
    max-width: 800px;
    width: 100%;
    margin: 50px auto;
    border: 1px solid maroon;
    box-shadow: #2b15453d 1px 10px 10px 5px;
    
  }