
.home {
    display: flex;
    flex-direction: column;
  
}

.button-box{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    
}


.top-intro-container {
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  .top-intro-box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../images/home-office.jpg');
    background-size: cover;
    opacity: .5;
    background-position: 25% 65%;
  }
  
  .top-intro {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    padding: 55px 45px 60px 45px;
    margin-top: 50px;
    margin-bottom: 100px;
    border-radius: 5px;
    max-width: 800px;
    opacity: .9;
  }

  .home-intro-h2 {
    font-weight: lighter;
  }
  

.top-intro-button-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 10px;
}

.home-about-box-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    padding: 50px;
    
}
.google-map-box {
    display: flex;
    max-width: 600px;
    max-height: 450px;
}

.google-map {
    display: flex;
    max-width: 600px;
    max-height: 450px;
}

.home-about-box {
    
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 1000px;
    margin-bottom: 15px;
    
}

.h2 {
    font-size: 30px;
}

.about-p {
    
    font-size: 18px;
}

.img-box img {
    max-width: 100%;
    height: auto;
    border-radius: 10px; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
  }
  

.services-box-wrapper {
    background-color: #f5f5f5;
    padding: 60px 20px;
  text-align: center;
}

.section-header {
    margin-top: 10px;
    margin-bottom: 10px;
    
}
.services-box {
    display: flex;
  justify-content: center;
  gap: 30px; 
  flex-wrap: wrap;
    
}

.services-title {
    color: #333;
    font-size: 1.5rem;
    margin: 15px 0;
  }

.services-boxes {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    text-align: left;
  }

  .services-boxes ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 15px 0;
  }

.list-ghost {
    list-style-type: none;
    color: #f5f5f5;
}

.home-reviews-box {
    padding: 50px 0px 50px 0px;
}

.reviews-boxes {
    margin: 25px;
}

.swiper {
    max-width: 800px;
    max-height: 300px;
    
  }

  /* @media screen and (max-width: 768px) {
    .top-intro-box {
      background-image: none;
      background-color: #f5f5f5;
      background-color: #000080;
    }
  } */

  
