.top-intro-box-about {
    display: flex;
    justify-content: center;
}

.intro-about {
    
    background-image:url('../images/Glenan-Kitchen-Left.jpg');
    

}


.top-intro-about {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 55px 45px 60px 45px;
    margin-top: 50px;
    margin-bottom: 100px;
    border-radius: 5px;
    max-width: 800px;
    
}

.blurb {
    font-size: 18px;
}

.blurb-big {
    font-size: 18px;
    font-weight: bold;
}

.about-box-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: center;
    margin: 50px 25px 50px 25px;
    /* max-width: 1000px; */
}

.about-image-box {
    max-width: 40%;
}

.about-image {
    max-width: 100%;
    border: 1px solid maroon;
    box-shadow: #2b15453d 1px 10px 10px 5px;
    border-radius: 10px;
}


.about-box {
    /* margin: 20px 450px; */
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    max-width: 800px;
    justify-content: center;
    
}

.about-box p {
    text-align: left;

}

@media screen and (max-width: 768px) {
    .about-box-container {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      align-items: center;
      gap: 20px; /* Reduces gap between sections */
    }
  
    .about-image-box,
    .about-box {
      flex: 1 1 100%; /* Full width for both image and text on mobile */
      max-width: 100%; /* Ensures they take full width */
      padding: 0; /* Remove padding for smaller screens */
    }
  
    .about-image {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Smaller shadow for mobile */
    }
  
    .about-box p {
      text-align: center; /* Centers text for better mobile layout */
    }
  }

.meet-team-container {
    margin: 100px 25px 50px 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #f5f5f5;
  }

  .meet-team-header-txt{
    margin-top: 50px;
  }
  
  .meet-team-box {
    display: flex;
    flex-wrap: wrap; /* Allows elements to wrap when needed */
    justify-content: center;
    margin: 100px 25px 50px 25px;
  }
  
  .headshot-img-container {
    flex: 1; /* Takes up equal space as .dylan-box */
    display: flex;
    justify-content: center;
    max-width: 250px;
    margin-bottom: 20px; /* Adds spacing for mobile */
  }
  
  .headshot-img-box {
    width: 250px;
   
  }
  
  .headshot-img {
    max-width: 100%;
    border: 1px solid maroon;
    box-shadow: #2b15453d 1px 10px 10px 5px;
    border-radius: 10px;
  }
  
  .dylan-box {
    flex: 2; /* Allows more space for text on larger screens */
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    max-width: 600px;
  }
  
  .member-title {
    margin-top: 5px;
    font-style: italic;
  }
  .bio {
    text-align: left;
    padding-top: 15px;
  }
  
  /* Media Query for smaller screens */
  @media screen and (max-width: 768px) {
    .meet-team-box {
      flex-direction: column; /* Stack elements vertically */
      align-items: center; /* Center content on mobile */
    }
  
    .headshot-img-container {
      margin-bottom: 20px; /* Adds spacing below the image */
    }
  
    .dylan-box {
      padding-left: 0; /* Remove padding for better alignment */
      text-align: center; /* Center-align text for a cleaner look */
    }
  
    .bio {
      text-align: center; /* Keep text justified for readability */
    }
  }