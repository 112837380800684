.footer-container {
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between; /* Evenly space the boxes */
    padding: 20px 40px;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    align-items: center;
    border-top: 2px solid #ddd; /* Adds a subtle top border to separate the footer */
    width: 100%; /* Allow the background to go full screen */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.left-box,
.right-box {
    flex: 1 1 45%; /* Ensures both sections take equal space */
    padding: 10px;
    text-align: left;
    max-width: 48%; /* Add max-width to keep the sections evenly spaced */
}

.social-logos-container {
    display: flex;
    gap: 15px; /* Space between social media icons */
}

.social-logos {
    height: 3rem;
    width: 3rem;
    color: maroon;
    margin: 5px;
    transition: transform 0.2s ease, color 0.3s ease;
}

.social-logos:hover {
    cursor: pointer;
    transform: scale(1.2); 
    color: #555; 
}

.footer-h2 {
    color: navy;
    margin-bottom: 10px;
    font-size: 1.5rem;
}

/* Responsive Layout */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column; /* Stack sections vertically */
        align-items: center;
        text-align: center; /* Center-align text for smaller screens */
    }

    .left-box,
    .right-box {
        flex: 1 1 100%; /* Make both sections take full width on mobile */
        padding: 15px;
        max-width: 100%; /* Ensure they take full width on mobile */
    }

    .social-logos-container {
        justify-content: center; /* Center social media icons */
        gap: 10px; /* Smaller gap between icons on mobile */
    }

    .social-logos {
        height: 2rem;
        width: 2rem;
    }
}
