

.header {
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
    
    padding-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 20px;
    background-color: white;
    box-shadow: 0 4px 2px -2px gray;
}

.nav-links-container {
    display: flex;
    
    
    
}

.links {
    display: flex;
    justify-content: center;
    font-size: 20px ;
}

.header-links {
    padding: 20px;
    color: navy;
    text-decoration: none;
    
}

.header-links:hover {
    color: maroon;
    /* text-decoration: underline; */
    
}

.request-estimate2 {
    color: white;
    background-color: maroon;
    font-size: 20px ;
    padding: 15px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-clip: padding-box;
    background-color: maroon;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
  
    /* flex-wrap: nowrap; */
}

.request-estimate2:hover, 
.request-estimate2:focus {
    background-color: #fb8332;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;  

}

.request-estimate2:hover {
    transform: translateY(-1px);

}

.request-estimate2:active {
    background-color: #c85000;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
  }

.nav-links-container.inactive{
    display: none;
}



.hamburger {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
    /* background-color: red; */
}

.burger-bar {
    width: 2rem;
    height: .3rem;
    border-radius: 10px;
    background-color: black;
    transform-origin: 1px;
    transition: all 0.3s linear;
}

.hamburger {
    display: none;
}

@media (max-width: 950px) {
    
    
    .hamburger {
        display: flex;
    /* justify-content: space-around; */
    /* flex-flow: column nowrap; */
    
    }
    
    .nav-links-container {
        display: none;
        
    }

    .nav-links-container.active{
        display: flex;
        flex-flow: column;
        z-index: 10;
        position:fixed;
        width: 100%;
        background-color: rgb(245, 235, 235);
        box-shadow: 0 4px 2px -2px gray;
        /* transform-origin: 1px;
        transition: all 0.3s linear; */
    }

    /* .nav-links-container-sticky {
        position: sticky;
    } */

    .burger1.open{
        transform: rotate(45deg);
        width: 2.25rem;
    }
    .burger2.open{
        display: none;
    }
    .burger3.open{
        transform: rotate(-45deg);
        width: 2.25rem;
    }

    }

  @media (min-width: 768px) {
    .nav-links-container.active{
        display: none;
    }
  }  

/* .logo {
    border: solid 5px #034e74;
    background-color: white;
    border-radius: 80px;
    overflow: hidden;
} */

