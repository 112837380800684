


.services-box-wrapper-servpage {
background-color: white;
margin-top: 50px;
    margin-bottom: 50px;
}


.services-intro {
    
    background-image:url('../images/Residential-Floor.jpg');
    
    
}



.top-intro-services {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 55px 45px 60px 45px;
    margin-top: 50px;
    margin-bottom: 100px;
    border-radius: 5px;
    max-width: 800px;
    
}
.list-ghost {
    list-style-type: none;
    color: white;
}
.services-box-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
  }

  .services-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .services-title {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
    font-weight: bold;
  }