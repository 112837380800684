

.contact-about {
    
    background-image:url('../images/Chase-Bath-1.jpg');
    

}

.contact-h3 {
    font-weight: lighter;
}



.contact-form {
    padding: 15px 15px 15px 15px;
}

.sub-box {
    margin-top: 10px;
}

.required {
    margin-bottom: 15px;
}

.form-box-wrapper {
    display: flex;
    justify-content: center;
    /* padding: 50px 0 50px 0; */
    text-align: center;
    margin: 50px ;
    
    
}

.form-box {
    display: flex;
    
    flex-direction: column;
    /* margin: 0 auto; */
    max-width: 880px;
    /* height: 450px; */
    text-align: left;
}



.name {
    display: flex;
    flex-direction: column;
}


.email-phone-box {
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 10px; */
}

.email-phone {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}


.message {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.input {
    box-shadow: 3px 3px 5px grey;
    border: 1px solid black;
    border-radius: 5px; 
    padding: 5px;
    font-size: 16px;
    margin-top: 5px;
}
.mesinput {
    display: flex;
    resize: none;
}

.asterisk {
    color: maroon;
    margin-left: 4px;
    margin-right: 5px;
}

.send_button {
    margin-top: 10px;
}

.errorMessage {
    color: red;
}

.alert {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border: 1px solid black;
    border-radius: 5px; 
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;

}

.alert-success {
    border: 1px solid green;
    background-color: rgba(0, 128, 0, .4);
    
    color: darkgreen;
    
}

.alert-danger {
    border: 1px solid red;
    background-color: rgba(255,0,0, .4);
    
    color: darkred;
    
}

.btn-close{
    border: none;
    background-color: rgba(0,0,0, 0);
    font-size: large;
}

.btn-close:hover {
    cursor: pointer;
}