.App {
  text-align: center;
  font-size: 16px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Arimo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

    /* Blue color should be #000080 */
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 30px;
  /* font-weight: lighter; */
}

h3 {
  font-size: 20px;
  /* font-weight: lighter; */

}

p {
  font-weight: lighter;
}


.img-cont {
  display: flex;
  justify-content: center;
}


.button-5 {
  align-items: center;
  background-clip: padding-box;
  font-family: "Arimo", sans-serif;
  background-color: maroon;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  /* font-weight: 600; */
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 210px;
  height: 2rem;
  margin-top: 20px;
  font-weight: bold;
}

.button-5:hover,
.button-5:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
  transform: translateY(-1px);
}

.button-5:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}

li {
  margin-top: 10px;
  text-align: left;
}